import XLSX from 'xlsx';
import moment from "moment"

export function exportarPlanilha(name,vet) {
  const fileName = name+"-"+moment().format("DDMMYYYY-hhmmss")+".xlsx";
  //console.log("fileName",fileName);
  const ws = XLSX.WorkSheet = XLSX.utils.json_to_sheet(vet);
  const wb = XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, name);
  XLSX.writeFile(wb, fileName);
}
